exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-analysis-tsx": () => import("./../../../src/pages/analysis.tsx" /* webpackChunkName: "component---src-pages-analysis-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-poster-tsx": () => import("./../../../src/pages/poster.tsx" /* webpackChunkName: "component---src-pages-poster-tsx" */),
  "component---src-pages-result-tsx": () => import("./../../../src/pages/result.tsx" /* webpackChunkName: "component---src-pages-result-tsx" */),
  "component---src-pages-results-tsx": () => import("./../../../src/pages/results.tsx" /* webpackChunkName: "component---src-pages-results-tsx" */),
  "component---src-pages-submit-tsx": () => import("./../../../src/pages/submit.tsx" /* webpackChunkName: "component---src-pages-submit-tsx" */)
}

